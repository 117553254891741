export const API = {
    session: 'api/Session',
    sessionSSO: 'api/Session/SSO',
    password: {
        changePassword: 'api/v2/Account/Password',
        forgotPassword: 'api/v2/Account/ForgotPassword',
        verifyResetPasswordToken: 'api/v2/Account/VerifyResetPasswordToken' ,
        resetPassword: 'api/v2/Account/ResetPassword',
        resetUserPassword: 'api/v2/Account/ChangePassword'
    },
    userSettings: {
        sync: 'api/Session/SyncUserSetting',
        settings: 'api/v2/UserSettings'
    },
    userProfile: {
        profile: 'api/BuySideUsers/UpdateProfile',
        emails: 'api/BuySideUsers/UpdateEmailAddresses',
        phoneNumbers: 'api/BuySideUsers/UpdatePhoneNumbers',
    },
    countries: 'api/Country/all',
    tokens: {
        jwtToken: 'api/JwtToken',
        websocketGatewayJwtToken: 'api/sockets/token',
        jwtTokenV2: 'api/v2/JwtToken'
    },
    logs: {
        httpResponse: `api/log/httpResponse`
    },
    termsOfUse: {
        published: 'api/TermsOfUse/Published',
        accept: 'api/TermsOfUse/Accept'
    },
    support: {
        buyside: 'api/Support/SendEmail',
        muni: 'v1/support-message',
        equity: 'api/email/send'
    }
};
