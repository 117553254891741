import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { tap, switchMap, catchError, finalize } from 'rxjs/operators';

import { WINDOW } from '@core/services/window.service';
import { RoutingHelperService } from '@core/services/routing-helper.service';

import { AuthApiActions } from '../actions';
import { AuthService } from '../../authentication/auth.service';
import { OktaService } from '@core/services/okta.service';

@Injectable()
export class LogoutEffect {

    constructor(
        private actions$: Actions,
        private authService: AuthService,
        private router: Router,
        private oktaService: OktaService,
        @Inject(WINDOW) private window: Window
    ) {}


    public logout$ = createEffect(() => this.actions$.pipe(
        ofType(
            AuthApiActions.AuthApiActionTypes.Logout
        ),
        switchMap(() =>
            this.authService.logout().pipe(
                finalize(() => {
                    this.logout();
                })
            )
        ),
        catchError((error) => of({...error, success: false}))
    ), { dispatch: false });


    public terminateSession$ = createEffect(() => this.actions$.pipe(
        ofType(
            AuthApiActions.AuthApiActionTypes.TerminateSession
        ),
        tap(() => {
            this.terminateSession();
        })
    ), { dispatch: false });

    private logout(): void {
        this.oktaService.signout().then(() => {
            this.authService.clearSession();
        });
    }

    private terminateSession(): void {
        this.authService.clearSession();

        const isAnonymousAccessAllowed = RoutingHelperService.isAnonymousAccessAllowed(this.window.location.href);

        if (!isAnonymousAccessAllowed) {
            this.router.navigate([RoutingHelperService.ROUTER_LINKS.login]);
        } else {
            return;
        }
    }
}
