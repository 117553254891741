import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { map, tap } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';

@Injectable({
    providedIn: 'root'
})
export class LoginGuard implements CanActivate {
    constructor(
        private store: Store<fromRoot.State>,
        private router: Router
    ) {}

    public canActivate(): any {
        const authObservable = this.store.pipe(select(fromRoot.getAuthState));

        return authObservable.pipe(
            tap(auth => {
                if (auth.isLoggedIn && !auth.isUserProfileConfirmed) {
                    this.router.navigate(['/login/confirmation']);
                }
            }),
            map(auth => {
                return !auth.isLoggedIn;
            })
        );
    }
}
