import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import * as fromRoot from '../../../../reducers';
import { AuthApiActions } from '@core/@state/actions';
import { RoutingHelperService } from '@core/services/routing-helper.service';
import { OktaService } from '@core/services/okta.service';

@Component({
    selector: 'oauth-callback',
    templateUrl: './oauth-callback.component.html',
    styleUrls: ['./oauth-callback.component.scss']
})
export class OAuthCallbackComponent implements OnInit {

    public isLoading$: Observable<boolean> = of(true);

    constructor(
        private store: Store,
        private router: Router,
        private oktaService: OktaService
    ) { }

    ngOnInit() {
        this.isLoading$ = this.store.pipe(
            select(fromRoot.getAuthState),
            map((authState) => authState.isLoading)
        );

        this.oktaService.isAuthenticated().then((isAuthenticated: boolean) => {
            if (isAuthenticated) {
                this.store.dispatch(new AuthApiActions.LoginSSO());
            } else {
                this.router.navigate([RoutingHelperService.ROUTER_LINKS.login]);
            }
        });
    }
}
