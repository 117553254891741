import { Inject, Injectable } from '@angular/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import { Tokens, OktaAuth } from '@okta/okta-auth-js';
import { RenderResultSuccess } from '@okta/okta-signin-widget';
import { OktaConfigService } from './okta-config.service';

export function oktaAuthFactory(oktaConfigService: OktaConfigService) {
    console.log('okta intance created');
    return {
        oktaAuth: new OktaAuth(oktaConfigService.oktaConfig)
    }
}

@Injectable()
export class OktaService {
    private readonly SIGNIN_WIDGET_IDENTIFIER = '#okta-sign-in-widget';

    constructor(@Inject(OKTA_AUTH) private oktaAuth: OktaAuth) {}

    get signInWidgetIdentifier() {
        return this.SIGNIN_WIDGET_IDENTIFIER;
    }

    get oktaAuthInstance() {
        return this.oktaAuth;
    }

    public isAuthenticated(): Promise<boolean> {
        return this.oktaAuth.isAuthenticated();
    }

    async signout(): Promise<void> {
        return this.oktaAuth.tokenManager.getTokens().then((tokens: Tokens) => {
            this.oktaAuth.closeSession();
            this.oktaAuth.signOut({ idToken: tokens.idToken, accessToken: tokens.accessToken });
        });
    }

    public clearTokensInTokenManager() {
        this.oktaAuth.tokenManager.clear();
    }

    public addTokensToTokenManager(tokens: Tokens) {
        this.oktaAuth.tokenManager.setTokens(tokens);
    }

    async signin(response: RenderResultSuccess) {
        this.addTokensToTokenManager(response.tokens);
        await this.oktaAuth.signInWithRedirect();
    }

    public getAccessToken(): string {
        return this.oktaAuth.getAccessToken();
    }
}
