import { ChangePasswordCredentials } from '../../modules/policies/models/user-credentials.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { StorageService } from '@core/services/storage.service';
import { noErrorHandlingForHeader, correlationIdRequestHeader } from '../constants/http-headers';
import { passwordExpiryData, xsrfTokenStorageKey } from '../constants/constants';
import { ProfileConfirmationModel } from '../../modules/login/models/profile-confirmation.model';
import { ResetPasswordCredentials, ResetPasswordToken } from '../../modules/reset-password/models/user-credentials.model';
import { API } from '@core/constants/api';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(
        private storageService: StorageService,
        private http: HttpClient
    ) {}


    public get noErrorHandlingHeader(): HttpHeaders {
        return new HttpHeaders({
            [noErrorHandlingForHeader]: '*'
        });
    }

    public login(userData: any): Observable<Object> {
        return this.http.post(API.session, userData, { headers: this.noErrorHandlingHeader });
    }

    public loginSSO(): Observable<Object> {
        return this.http.post(API.sessionSSO, {}, { headers: this.noErrorHandlingHeader });
    }

    public logout(): Observable<Object> {
        return this.http.delete(API.session, { headers: this.noErrorHandlingHeader });
    }

    public clearSession(): void {
        this.storageService.removeItem(
            xsrfTokenStorageKey,
            passwordExpiryData
        );
    }

    public resetUserPassword(userId: string): Observable<any> {
        return this.http.put(API.password.resetUserPassword, { userId }, { headers: this.noErrorHandlingHeader });
    }

    public resetPasswordRequest(mail: any): Observable<any> {
        return this.http.post(API.password.forgotPassword, { Email: mail }, { headers: this.noErrorHandlingHeader });
    }

    public verifyResetPasswordToken(token: ResetPasswordToken): Observable<any> {
        return this.http.post(API.password.verifyResetPasswordToken, token, { headers: this.noErrorHandlingHeader });
    }

    public resetPassword(credentials: ResetPasswordCredentials, correlationId: string): Observable<any> {
        const headers = new HttpHeaders({
            [noErrorHandlingForHeader]: '*',
            [correlationIdRequestHeader]: correlationId
        });

        return this.http.post(API.password.resetPassword, credentials, { headers });
    }

    public changePassword(credentials: ChangePasswordCredentials): Observable<any> {
        return this.http.put(API.password.changePassword, credentials, { headers: this.noErrorHandlingHeader });
    }

    public setXsrfToken(xsrf_token: string): void {
        const xsrfTokenToString = JSON.stringify({ value: xsrf_token, type: 'string' });
        this.storageService.setItem(xsrfTokenStorageKey, xsrfTokenToString);
    }

    public confirmProfile(profile: ProfileConfirmationModel): Observable<any> {
        return this.http.put(API.userProfile.profile, profile, { headers: this.noErrorHandlingHeader });
    }
}
