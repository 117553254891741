import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { jwtTokenRequestHeader } from '@core/constants/http-headers';
import { OktaService } from '@core/services/okta.service';

@Injectable()
export class OktaInterceptor implements HttpInterceptor {

    constructor(private oktaService: OktaService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        /**
         * If Authorization header has been set by Component which made request
         * Platform should not overwrite Auth header (component which makes request
         * has an option to avoid attaching Auth header by setting Authorization: 'none')
         */
        if (request.headers.get(jwtTokenRequestHeader) === 'none' || request.url.includes('odata')) {
            let authReq: HttpRequest<any>;

            authReq = request.clone({
                headers: request.headers.delete(jwtTokenRequestHeader)
            });

            return next.handle(authReq);
        }

        return this.checkToken(request, next);
    }

    private checkToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let token: string;
        /**
         * Do not check and re-fetch JWT once user is not logged in,
         * or for requests which do not require JWT
         */
        if (
            request.url.indexOf('JwtToken') !== -1 ||
            request.url.indexOf('SSO') === -1 &&
            request.url.indexOf('deal-info') === -1 &&
            request.url.indexOf('orders-info') === -1 &&
            request.url.indexOf('bsp-muni-bff') === -1 &&
            request.url.indexOf('pom-bff') === -1 &&
            !this.isJwtTokenV2Needed(request.url) &&
            !/FixedIncomeDeal\/v2\/\d+$/.test(request.url) &&
            request.headers.get(jwtTokenRequestHeader) !== 'BSP-JWT'
        ) {
            return next.handle(request);
        }
        token = this.oktaService.getAccessToken();
        request = this.addToken(request, token);
        return next.handle(request);
    }

    private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`
            }
        });
    }

    private isJwtTokenV2Needed(url: string): boolean {
        return url.indexOf('equity-bff') !== -1
            || url.indexOf('client-logging') !== -1;
    }
}
