import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { RoutingHelperService } from '@core/services/routing-helper.service';

@Injectable({
    providedIn: 'root',
})
export class ResetPasswordGuard implements CanActivate {

    constructor(private router: Router) {
    }

    public canActivate(route: ActivatedRouteSnapshot): boolean {
        if (!!route.queryParams.code && !!route.queryParams.u) {
            return true;
        } else {
            this.router.navigate([RoutingHelperService.ROUTER_LINKS.login]);
        }

        return false;
    }
}
