import { Inject, Injectable } from '@angular/core';
import { OktaAuthOptions } from '@okta/okta-auth-js';

import { WINDOW } from './window.service';
import { BspConfiguration } from '../configuration/bsp-configuration';

@Injectable()
export class OktaConfigService {
    private cachedOktaConfig: OktaAuthOptions;
    private isSingleSignOn: boolean;

    constructor(@Inject(WINDOW) private window: Window) {}

    private getBSPConfiguration(): BspConfiguration {
        const configuration: BspConfiguration = (window as any).buySideConfiguration;
        if (!configuration) {
            return null;
        }
        this.isSingleSignOn = configuration.enableSingleSignOn;
        return configuration;
    };

    get oktaConfig(): OktaAuthOptions {
        if (this.cachedOktaConfig) {
            console.log('returning cached okta config');
            return this.cachedOktaConfig;
        }
        const bspConfiguration = this.getBSPConfiguration();
        this.cachedOktaConfig = {
            issuer: bspConfiguration.oidcAuthority,
            clientId: bspConfiguration.oidcClientId,
            redirectUri: `${this.window.location.origin}${bspConfiguration.oidcRedirectUrl}`,
            scopes: bspConfiguration.oidcScopes.split(' ')
        };
        console.log('returning new okta config');
        return this.cachedOktaConfig;
    }

    get isOktaEnabled(): boolean {
        return this.isSingleSignOn;
    }

    get baseUrl(): string {
        return this.oktaConfig.issuer.split('/oauth2')[0];
    }
}
