import {
    ActionReducerMap,
    createSelector,
    createFeatureSelector,
    ActionReducer,
    MetaReducer,
    Action,
    UPDATE,
} from '@ngrx/store';
import * as fromRouter from '@ngrx/router-store';

import { environment } from '@environments/environment';

import * as fromShared from '@core/@state/reducers/shared.reducer';
import * as fromLayout from '@core/@state/reducers/layout.reducer';
import * as fromAuth from '@core/@state/reducers/auth.reducer';
import * as fromSupport from '@core/@state/reducers/support.reducer';
import * as fromUserProfile from '@core/@state/reducers/user-profile.reducer';
import * as fromUserSettingsPage from '@core/@state/reducers/user-settings-page.reducer';
import * as fromLogs from '@core/@state/reducers/logs.reducer';

import { Params, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

export interface State {
    router: fromRouter.RouterReducerState;
    shared: fromShared.State;
    layout: fromLayout.State;
    auth: fromAuth.State;
    support: fromSupport.State;
    userProfile: fromUserProfile.State;
    userSettingsPage: fromUserSettingsPage.State;
    logs: fromLogs.State;
}

export const reducers: ActionReducerMap<State> = {
    router: fromRouter.routerReducer,
    shared: fromShared.sharedReducer,
    layout: fromLayout.layoutReducer,
    auth: fromAuth.authReducer,
    support: fromSupport.supportReducer,
    userProfile: fromUserProfile.userProfileReducer,
    userSettingsPage: fromUserSettingsPage.userSettingsPageReducer,
    logs: fromLogs.logsReducer
};

export interface RouterStateUrl {
    url: string;
    params: Params;
    queryParams: Params;
}

@Injectable()
export class CustomSerializer implements fromRouter.RouterStateSerializer<RouterStateUrl> {
    public serialize(routerState: RouterStateSnapshot): RouterStateUrl {
        let route = routerState.root;
        let paramsMap = {};

        while (route.firstChild) {
            route = route.firstChild;
            paramsMap = {
                ...paramsMap,
                ...route.params
            };
        }

        const {
            url,
            root: { queryParams },
        } = routerState;

        return { url, params: paramsMap, queryParams };
    }
}

export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
    return (state: State, action: Action): State => {
        const result = reducer(state, action);

        if (action.type !== UPDATE) {
            console.groupCollapsed(action.type);
            console.log('prev state', state);
            console.log('action', action);
            console.log('next state', result);
            console.groupEnd();
        }

        return result;
    };
}

export const metaReducers: MetaReducer<State>[] = !environment.production
    ? [logger]
    : [];


export const getSharedState = createFeatureSelector<fromShared.State>('shared');
export const getLayoutState = createFeatureSelector<fromLayout.State>('layout');
export const getAuthState = createFeatureSelector<fromAuth.State>('auth');
export const getSupportState = createFeatureSelector<fromSupport.State>('support');
export const getUserProfileState = createFeatureSelector<fromUserProfile.State>('userProfile');
export const getRouterState = createFeatureSelector<fromRouter.RouterReducerState>('router');

export const getUserSettingsState = createSelector(getSharedState, fromShared.getSettingsState);
export const getMuniUserSettingsState = createSelector(getSharedState, fromShared.getMuniSettingsState);
export const getPermissionsState = createSelector(getSharedState, fromShared.getPermissionsState);
export const getConfigurationState = createSelector(getSharedState, fromShared.getConfigurationState);
export const getCountriesState = createSelector(getSharedState, fromShared.getCountriesState);

export const getUserProfile = createSelector(getSharedState, fromShared.getUserProfile);
export const getContainerId = createSelector(getSharedState, fromShared.getContainerId);
export const getIsAccessingFromUS = createSelector(getSharedState, fromShared.getIsAccessingFromUS);

export const getUserName = createSelector(getSharedState, fromShared.getUserName);

export const getUserEmail = createSelector(getSharedState, fromShared.getUserEmail);

export const getHasMmdDataPermission = createSelector(getPermissionsState, permissions => permissions.hasMunicipalMmdData);

export const getMuniBffUrl = createSelector(getConfigurationState, configuration => {
    if (!configuration) {
        return null;
    }

    return configuration.muniBffUrl;
});

export const getEnvironment = createSelector(getConfigurationState, configuration => {
    if (!configuration) {
        return null;
    }

    return configuration.environment;
});

export const getIsFixedIncomePermissions = createSelector(getPermissionsState, permissions => {
    if (!permissions) {
        return null;
    }

    return permissions.canViewFixedIncomeDealMonitor
        || permissions.canViewDealQuery
        || permissions.canViewActivityStream
        || permissions.canViewCompliance;
});

export const getIsMuniPermissions = createSelector(getPermissionsState, permissions => {
    if (!permissions) {
        return null;
    }

    return permissions.canViewMunicipalDealMonitor || permissions.canViewMunicipalStreetCalendar;
});

export const getCanViewFixedIncomePages = createSelector(getIsFixedIncomePermissions, getIsMuniPermissions,
    (isFixedIncomePermissions, isMuniPermissions) => {
        return isFixedIncomePermissions || !isMuniPermissions;
    }
);

export const getSupportGroupEmail = createSelector(getConfigurationState, (configuration) => {
    return configuration.supportGroupEmail;
});

export const getCopyRightText = createSelector(getConfigurationState, (configuration) => {
    const applicationCopyrightText = configuration.applicationCopyrightText || '';
    const applicationVersionNumber = configuration.applicationVersionNumber || '';

    return applicationCopyrightText.replace('%applicationVersionNumber%', applicationVersionNumber);
});

export const getIpreoSocketsAppCode = createSelector(getConfigurationState, (configuration) => configuration.ipreoSocketsAppCode);

export const getIsLoggedIn = createSelector(getAuthState, authState => authState.isLoggedIn);

export const getIsUserProfileConfirmed = createSelector(getAuthState, authState => authState.isUserProfileConfirmed);

export const getIsShowUserPasswordResetModal = createSelector(getAuthState, authState => authState.isShowUserPasswordResetModal);

export const getIsPasswordResetLoading = createSelector(getAuthState, authState => authState.resetUserPassword.isLoading);

export const getHomePage = createSelector(getUserSettingsState, userSettings => {
    if (!userSettings) {
        return null;
    }

    return userSettings.profile.homePage;
});

export const getUserCompanies = createSelector(getUserSettingsState, userSettings => {
    if (!userSettings) {
        return null;
    }

    return userSettings.companies;
});

export const getUserCompanySortOrder = createSelector(getUserSettingsState, userSettings => {
    if (!userSettings) {
        return null;
    }

    return userSettings.companySortOrder;
});

export const getUserSettingsPageState = createFeatureSelector<State>('userSettingsPage');

export const getNumberFormat = createSelector(getUserSettingsState, userSettings => {
    if (!userSettings) {
        return null;
    }

    return userSettings.numberFormat.key;
});

export const getGroupSeparator = createSelector(getUserSettingsState, userSettings => {
    if (!userSettings) {
        return null;
    }

    return userSettings.numberFormat.format.GROUP_SEP;
});

export const getCountries = createSelector(getCountriesState, countriesState => {
    return countriesState.countries;
});

export const getIsEquityPermissions = createSelector(getPermissionsState, permissions => {
    if (!permissions) {
        return null;
    }

    return permissions.canViewEquityDealMonitor
        || permissions.canViewEquityDealQuery
        || permissions.canViewEquityPublicDeals;
});

export const getIsEquityDealMonitorPermissions = createSelector(getPermissionsState, permissions => {
    if (!permissions) {
        return null;
    }

    return permissions.canViewEquityDealMonitor;
});

export const getIsEquityPublicDealsOnlyPermissions = createSelector(getPermissionsState, permissions => {
    if (!permissions) {
        return null;
    }

    return !permissions.canViewEquityDealMonitor
        && !permissions.canViewEquityDealQuery
        && permissions.canViewEquityPublicDeals;
});

export const getIsOnlyEquityPermissions = createSelector(getIsFixedIncomePermissions, getIsMuniPermissions, getIsEquityPermissions,
    (isFixedIncomePermissions, isMuniPermissions, isEquityPermissions) => {
        return !isFixedIncomePermissions && !isMuniPermissions && isEquityPermissions;
    }
);

export const getIsOnlyEquityPublicDealsPermissions = createSelector(getIsFixedIncomePermissions, getIsMuniPermissions, getIsEquityPublicDealsOnlyPermissions,
    (isFixedIncomePermissions, isMuniPermissions, isEquityPublicDealsOnlyPermissions) => {
        return !isFixedIncomePermissions && !isMuniPermissions && isEquityPublicDealsOnlyPermissions;
    }
);

export const getComplianceEnabled = createSelector(getConfigurationState, configuration => {
    if (!configuration) {
        return false;
    }

    return configuration.complianceEnabled;
});

export const getOperationalEntitiesEnabled = createSelector(getConfigurationState, configuration => {
    if (!configuration) {
        return false;
    }

    return configuration.operationalEntitiesEnable;
});

export const getIsAnyOperationalEntityCompany = createSelector(
    getOperationalEntitiesEnabled,
    getPermissionsState,
    (isEnabled, permissions) =>
        isEnabled && permissions.companiesWithOperationalEntitiesPermission.length > 0);

export const getOperationalEntityCompanies = createSelector(
    getPermissionsState,
    getUserCompanies,
    (permissions, companies) => permissions.companiesWithOperationalEntitiesPermission
        .map(companyId => companies.find(cc => cc.orionId === companyId)));
